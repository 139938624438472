// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import VoipCalls from "../../blocks/VoipCalls/src/VoipCalls";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Analytics from "../../blocks/analytics/src/Analytics";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import ContactsList from "../../blocks/ContactsList/src/ContactsList";
import ContactsIntegration from "../../blocks/ContactsIntegration/src/ContactsIntegration";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import ApiIntegration from "../../blocks/apiintegration/src/ApiIntegration";
import InternetAccess2 from "../../blocks/InternetAccess2/src/InternetAccess2";
import HamburgerMenu from "../../blocks/HamburgerMenu/src/HamburgerMenu";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Dashboard from "../../blocks/dashboard/src/Dashboard";
import Splashscreen from "../../blocks/splashscreen/src/Splashscreen";
import ElasticSearch from "../../blocks/ElasticSearch/src/ElasticSearch";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import CustomerDialpadKeyboard2 from "../../blocks/CustomerDialpadKeyboard2/src/CustomerDialpadKeyboard2";
import EmailNotifications from "../../blocks/EmailNotifications/src/EmailNotifications";



const routeMap = {
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
VoipCalls:{
 component:VoipCalls,
path:"/VoipCalls"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
ContactsList:{
 component:ContactsList,
path:"/ContactsList"},
ContactsIntegration:{
 component:ContactsIntegration,
path:"/ContactsIntegration"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
ApiIntegration:{
 component:ApiIntegration,
path:"/ApiIntegration"},
InternetAccess2:{
 component:InternetAccess2,
path:"/InternetAccess2"},
HamburgerMenu:{
 component:HamburgerMenu,
path:"/HamburgerMenu"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
Dashboard:{
 component:Dashboard,
path:"/Dashboard"},
Splashscreen:{
 component:Splashscreen,
path:"/Splashscreen"},
ElasticSearch:{
 component:ElasticSearch,
path:"/ElasticSearch"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
CustomerDialpadKeyboard2:{
 component:CustomerDialpadKeyboard2,
path:"/CustomerDialpadKeyboard2"},
EmailNotifications:{
 component:EmailNotifications,
path:"/EmailNotifications"},

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;